import heroImage from './hero-image';
import longformGallery from './longform-gallery';
import longformRow from './longform-row';
import parallaxImageTextRight from './parallax-image-text-right';
import charterSpecialFeaturesRow from './charter-special-features-row';
import centeredCarousel from './centered-carousel';
import standFirstTextBlock from './standfirst-textblock';
import dropCapTextBlock from './drop-cap-textblock';

const variants = [
  heroImage,
  longformGallery,
  longformRow,
  parallaxImageTextRight,
  charterSpecialFeaturesRow,
  centeredCarousel,
  standFirstTextBlock,
  dropCapTextBlock
];

export default variants;
