import { commonProperties } from '../constants';

export const charterCruisingAreas = {
  type: 'charter-cruising-areas',
  name: 'Charter cruising areas',
  icon: 'fas fa-bars',
  settings: {},
  formSchema: {
    properties: {
      ...commonProperties,
      elementTitle: {
        label: 'Title',
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      },
      seasons: {
        label: 'Insertion type',
        type: 'string',
        showInForm: true,
        formFieldType: 'select',
        options: [
          ['all', 'All'],
          ['summer', 'Summer'],
          ['winter', 'Winter'],
          ['summer_and_winter', 'Summer and winter']
        ],
        selected: 'all'
      },
      fallbackUrl: {
        label: 'Search URL to link to if no listing page is set',
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      }
    }
  },
  allowChildren: false,
  color: 'purple',
  group: 'data'
};
